// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

var $ = window.$ = window.jQuery = require('jquery');
import 'datatables.net-se'
import 'datatables.net-buttons-se'
import 'datatables.net-select-se'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'jszip'

require('smartwizard');
require('pdfmake/build/pdfmake.js');
require('pdfmake/build/vfs_fonts.js');
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import 'datatables.net-responsive-se'
import 'datatables.net-rowreorder-se'
import './datetime-moment.js'
import "../stylesheets/application";
import './semantic.min.js';
import './summernote-lite.min.js';
import './summernote-de-DE.min.js';
import './init.coffee';
import './datatable.coffee';
import './user.coffee';
import './statistics_forwarding.coffee';
import './statistics_dashboard_global.coffee';
import './statistics_dashboard_admin.coffee';
//import './statistics_project.coffee';
//import './statistics_dashboard.coffee';
import './statistics.coffee'; 
import './forwarding.coffee';
import './carousel.coffee';
import './corona_registry.coffee';
import '../channels/consumer.js';
import '../channels/index.js';
import '../channels/table_view_channel.js';
import '../channels/checkout_view_channel.js';
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below. semantic.min.js
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
