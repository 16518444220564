import I18n from 'i18n-js'
moment = require('moment')
validate = require("validate.js")
t=null
apikeytable=null
smartwizard = null
jQuery(document).on 'turbolinks:load', ->    
    if $("body").data("controller") == "forwardings" && ($("body").data("action") == "edit" || $("body").data("action") == "new")
        I18n.translations = JSON.parse(gon.js_translations)
        I18n.defaultLocale = "de"
        I18n.locale = "de"
        
        smartwizard = $('#smartwizard').smartWizard({
            selected: 0, 
            theme: 'arrows', 
            justified: true,
            darkMode:false, 
            autoAdjustHeight: false, 
            cycleSteps: false,
            backButtonSupport: true,
            enableURLhash: false,
            lang: { 
                next: 'Nächste',
                previous: 'Zurück'
            }
        })
        $('#forwarding_description').summernote({
            lang: 'de-DE',
            tabsize: 2,
            height: 120,
            toolbar: [['style', ['style']], ['font', ['bold', 'underline', 'clear']], ['color', ['color']], ['para', ['ul', 'ol', 'paragraph']], ['table', ['table']], ['insert', ['link', 'picture', 'video']], ['view', ['fullscreen', 'codeview', 'help']]]
        }) 

$(document).on 'ajax:success', '#apikeytableform', (response, status, xhr) ->
    $("input[name=comment]").val("")
    $("input[name=expiry_date]").val("")
    apikeytable.row.add({"comment": response.detail[0].comment, "key": response.detail[0].api_key, "expiry_date": response.detail[0].expiry_date, "delete": response.detail[0].delete}).draw( false )

$(document).on 'ajax:success', '.apikeytabledelete', (response, status, xhr) ->
    $(this).closest('tr').fadeOut();

$(document).on 'click', '.showapikeytable',  ->
    $('#apikeytable').DataTable().destroy()
    apikeytable = $('#apikeytable').DataTable({
        observeChanges:true,
        language: {
            url: '/datatable_translation'
        }
        ajax: "/forwardings/"+$(this).data("id")+"/apikey?view="+$(this).data("view")+"&scope=get",
        sAjaxDataProp: "api_keys",
        columns: [
            { "data": "comment" }, 
            { "data": "key" },
            { "data": "expiry_date" },
            { "data": "delete" }
            
        ],
        dom: "<'ui stackable grid'<'two column row' <'column' B > <'column' f>> <'one column row' <'column' rt>> <'three column row' <'column' i ><'column' ><'column' p >>>",
        lengthChange: false,
        lengthMenu: [5],
        buttons: [
            $.extend(false, {}, extend: 'csvHtml5', className: 'ui primary button', fieldSeparator: ';', charset: 'UTF-8')
        ], 
        order: [[ 0, "desc" ]], 
        drawCallback: (settings) ->
            $('.dropdown').dropdown() 
        }
    )
    $("#id").val($(this).data("id"))
    $("#view").val($(this).data("view"))
    
    $("#apikeytablemodal").modal({
        onShow: ->
            today = new Date()
            $('#expiry_date_calendar').calendar({
                minDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
                ampm: false,
                formatter: 
                    date: (date, settings) -> 
                        if !date
                            return ''
                        day = date.getDate()
                        month = date.getMonth() + 1
                        year = date.getFullYear()
                        hour = date.getHours()
                        minute = date.getMinutes()
                        return day + '.' + month + '.' + year 
            });
            $("input[name=comment]").val("")
            $("input[name=expiry_date]").val("")
    }).modal('show')
    

$(document).on 'click', '#emailsendingbutton', ->
    $("#emailsending").modal('show')

$(document).on 'click', '#show_corona', ->
    $.get
        url: "/forwardings/"+$("body").data("id")+"/corona_visits?date_start="+$("#corona_start_range input").val()+"&date_end="+$("#corona_end_range input").val()
        success: (data) ->
            if data.value_empty
                $("#corona_message").html("<div class='ui yellow message'>"+I18n.t("statistics.statistics_empty")+"</div>")
            blob = new Blob([data], {type: 'text/csv'});
            a = window.document.createElement("a")
            a.href = window.URL.createObjectURL(blob, {type: "text/csv"})
            a.download = "Coronaliste.csv"
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)

$(document).on 'click', '#checkout', ->
    $.post
        url: "/registration/"+$("#checkout").data("id")+"/checkout?key="+$("#checkout").data("jwt")
        success: (data) ->
            $("#checkout").prop('disabled', true); 
            $("#checkout_success").removeClass("d-none");
            $(".checkout_no").addClass("d-none"); 
        error: (data) ->
            $("#message").html("<div class='ui red message'>"+I18n.t("corona.checkout_error")+"</div><br>")
            $("#checkout_success").addClass("d-none");
            $(".checkout_no").removeClass("d-none");  

$(document).on 'click', '#forwarding_billing_monthly', ->
    $("#billingshow").find(".value").html("49,90 EUR")
    $("#billingshow").find(".label").html("pro Monat (netto)")

$(document).on 'click', '#forwarding_billing_fix', ->
    $("#billingshow").find(".value").html("249.5 EUR")
    $("#billingshow").find(".label").html("für 6 Monate (netto)")

$(document).on 'click', '.checkout_button', ->
    button_tr = this
    today = new Date()
    dd = String(today.getDate()).padStart(2, '0');
    mm = String(today.getMonth() + 1).padStart(2, '0'); 
    yyyy = today.getFullYear();
    HH = String(today.getHours()).padStart(2, '0');
    MM = String(today.getMinutes()).padStart(2, '0');
    $.post
        url:  "/forwardings/"+$("body").data("id")+"/checkout"
        data: {c_id: $(this).data("id"), token: $("#tableforcheckoutview").data("token")}
        success: (data) ->
            $(button_tr).closest("tr").remove()
            $($(".statistic")[0]).find(".value").text(parseInt($($(".statistic")[0]).find(".value").text())-1)
            $($(".statistic")[3]).find(".value").text(mm + '.' + dd + '.' + yyyy + ' ' + HH + ':' + MM)
        error: (data) ->

$(document).on 'click', '#addcoronaregistry', ->
    $("#addcoronaregistrymodal").modal("show")

$(document).on 'ajax:success', '#new_corona_registry', (response, status, xhr) ->
    $("#corona_registry_errors").closest(".message").addClass("d-none")
    $("#corona_registry_name").val("")
    $("#corona_registry_phone").val("")
    $("#corona_registry_city").val("")
    $("#corona_registry_zip").val("")
    $("#corona_registry_street").val("")
    $("#corona_registry_table_number").val("")
    $("input[name='guest[]']").closest(".field").remove()
    $("#addcoronaregistrymodal").modal("hide")

$(document).on 'ajax:error', '#new_corona_registry', (response, status, xhr) ->
    $("#corona_registry_errors").closest(".message").removeClass("d-none")
    $("#corona_registry_errors").html("")
    for message,message2 of response.detail[0]
        $("#corona_registry_errors").append("<li>"+message2.join()+"</li>")

#https://validatejs.org/#validate-nested
#http://techlaboratory.net/jquery-smartwizard#documentation
$(document).on 'leaveStep', '#smartwizard', (e, anchorObject, currentStepIndex, nextStepIndex, stepDirection) ->
    $(anchorObject).removeClass("danger")
    if currentStepIndex == 0
        forwarding_name_validate()        
        forwarding_company_id_validate()
        if $(anchorObject).hasClass("danger")
            return false
    else if currentStepIndex == 1 
        forwarding_zip_validate()
        forwarding_city_validate()
        forwarding_street_validate()
        forwarding_phone_validate()
        forwarding_mobilphone_validate()
        if $(anchorObject).hasClass("danger")
            return false
    else if currentStepIndex == 2
        if $(anchorObject).hasClass("danger")
            return false
    else if currentStepIndex == 3
        forwarding_google_validate()
        forwarding_instagram_validate()
        forwarding_facebook_validate()
        if $(anchorObject).hasClass("danger")
            return false
    else if currentStepIndex == 4
        forwarding_check_out_after_validate()
        forwarding_max_guests_validate()
        if $(anchorObject).hasClass("danger")
            return false

forwarding_name_validate = ->
    constraints =
        name:
            presence: 
                message: I18n.t("validate.reguired_name")
                allowEmpty: false
            length: 
                minimum: 6
                message: I18n.t("validate.length")
            exclusion:
                within: [ 'Miky mouse' ]
                message: "^%{value}" + I18n.t("validate.exclusion")
    error = validate({name: $("#forwarding_name").val()}, constraints)
    if error != undefined && error.name != undefined
        $("#forwarding_name").closest(".field").find("span").remove()
        $("#forwarding_name").closest(".field").addClass("error")
        $("#forwarding_name").closest(".field").append("<span class='ui red text'>"+error.name.join(" <br>")+"</span>")
        $('#smartwizard').smartWizard("setOptions",{errorSteps: [0]})
    else
        $("#forwarding_name").closest(".field").find("span").remove()
        $("#forwarding_name").closest(".field").removeClass("error")

$(document).on 'focusout', '#forwarding_name', ->
    forwarding_name_validate()

forwarding_max_guests_validate = ->
    constraints =
        max_guests:
            presence: 
                message: I18n.t("validate.reguired_max_guests")
                allowEmpty: false
            numericality: 
                notValid: I18n.t("validate.notInteger") 
                onlyInteger: true
                notInteger: I18n.t("validate.notInteger")
                greaterThan: 0
                notGreaterThan: I18n.t("validate.notGreaterThan")
    error = validate({max_guests: parseInt($("#forwarding_max_guests").val())}, constraints)
    if error != undefined && error.max_guests != undefined
        $("#forwarding_max_guests").closest(".field").find("span").remove()
        $("#forwarding_max_guests").closest(".field").addClass("error")
        $("#forwarding_max_guests").closest(".field").append("<span class='ui red text'>"+error.max_guests.join(" <br>")+"</span>")
        $('#smartwizard').smartWizard("setOptions",{errorSteps: [0]})
    else
        $("#forwarding_max_guests").closest(".field").find("span").remove()
        $("#forwarding_max_guests").closest(".field").removeClass("error")

$(document).on 'focusout', '#forwarding_max_guests', ->
    forwarding_max_guests_validate()

forwarding_company_id_validate = ->
    constraints =
        company_id:
            presence: 
                message: I18n.t("validate.reguired_company_id")
                allowEmpty: false
    error = validate({company_id: $("#forwarding_company_id").val()}, constraints)
    if error != undefined && error.company_id != undefined
        $("#forwarding_company_id").closest(".field").find("span").remove()
        $("#forwarding_company_id").closest(".field").addClass("error")
        $("#forwarding_company_id").closest(".field").append("<span class='ui red text'>"+error.company_id.join(" <br>")+"</span>")
        $('#smartwizard').smartWizard("setOptions",{errorSteps: [0]})
    else
        $("#forwarding_company_id").closest(".field").find("span").remove()
        $("#forwarding_company_id").closest(".field").removeClass("error")

$(document).on 'focusout', '#forwarding_company_id', ->
    forwarding_company_id_validate()



forwarding_zip_validate = ->
    constraints =
        zip:
            presence: 
                message: I18n.t("validate.reguired_zip")
                allowEmpty: false
            format: 
                pattern: /\d{5}(-\d{4})?/
                message: I18n.t("validate.format_zip")
    error = validate({zip: $("#forwarding_zip").val()}, constraints)
    if error != undefined && error.zip != undefined
        $("#forwarding_zip").closest(".field").find("span").remove()
        $("#forwarding_zip").closest(".field").addClass("error")
        $("#forwarding_zip").closest(".field").append("<span class='ui red text'>"+error.zip.join(" <br>")+"</span>")
        $('#smartwizard').smartWizard("setOptions",{errorSteps: [1]})
    else
        $("#forwarding_zip").closest(".field").find("span").remove()
        $("#forwarding_zip").closest(".field").removeClass("error")

$(document).on 'focusout', '#forwarding_zip', ->
    forwarding_zip_validate()


forwarding_city_validate = ->
    constraints =
        city:
            presence: 
                message: I18n.t("validate.reguired_city")
                allowEmpty: false
    error = validate({city: $("#forwarding_city").val()}, constraints)
    if error != undefined && error.city != undefined
        $("#forwarding_city").closest(".field").find("span").remove()
        $("#forwarding_city").closest(".field").addClass("error")
        $("#forwarding_city").closest(".field").append("<span class='ui red text'>"+error.city.join(" <br>")+"</span>")
        $('#smartwizard').smartWizard("setOptions",{errorSteps: [1]})
    else
        $("#forwarding_city").closest(".field").find("span").remove()
        $("#forwarding_city").closest(".field").removeClass("error")

$(document).on 'focusout', '#forwarding_city', ->
    forwarding_city_validate()

forwarding_street_validate = ->
    constraints =
        street:
            presence: 
                message: I18n.t("validate.reguired_street")
                allowEmpty: false
    error = validate({street: $("#forwarding_street").val()}, constraints)
    if error != undefined && error.street != undefined
        $("#forwarding_street").closest(".field").find("span").remove()
        $("#forwarding_street").closest(".field").addClass("error")
        $("#forwarding_street").closest(".field").append("<span class='ui red text'>"+error.street.join(" <br>")+"</span>")
        $('#smartwizard').smartWizard("setOptions",{errorSteps: [1]})
    else
        $("#forwarding_street").closest(".field").find("span").remove()
        $("#forwarding_street").closest(".field").removeClass("error")

$(document).on 'focusout', '#forwarding_street', ->
    forwarding_street_validate()

forwarding_phone_validate = ->
    constraints =
        phone:
            presence: 
                message: I18n.t("validate.reguired_phone")
                allowEmpty: false
            format: 
                pattern: /(\(?([\d \-\)\–\+\/\(]+){6,}\)?([ .\-–\/]?)([\d]+))/
                message: I18n.t("validate.format_phone")
    error = validate({phone: $("#forwarding_phone").val()}, constraints)
    if error != undefined && error.phone != undefined
        $("#forwarding_phone").closest(".field").find("span").remove()
        $("#forwarding_phone").closest(".field").addClass("error")
        $("#forwarding_phone").closest(".field").append("<span class='ui red text'>"+error.phone.join(" <br>")+"</span>")
        $('#smartwizard').smartWizard("setOptions",{errorSteps: [1]})
    else
        $("#forwarding_phone").closest(".field").find("span").remove()
        $("#forwarding_phone").closest(".field").removeClass("error")

$(document).on 'focusout', '#forwarding_phone', ->
    forwarding_phone_validate()

forwarding_mobilphone_validate = ->
    constraints =
        mobilphone:
            format: 
                pattern: /(?!\s*$)(\(?([\d \-\)\–\+\/\(]+){6,}\)?([ .\-–\/]?)([\d]+))/
                message: I18n.t("validate.format_phone")
    error = validate({mobilphone: if $("#forwarding_mobilphone").val() == "" then null else $("#forwarding_mobilphone").val()}, constraints)
    if error != undefined && error.mobilphone != undefined
        $("#forwarding_mobilphone").closest(".field").find("span").remove()
        $("#forwarding_mobilphone").closest(".field").addClass("error")
        $("#forwarding_mobilphone").closest(".field").append("<span class='ui red text'>"+error.mobilphone.join(" <br>")+"</span>")
        $('#smartwizard').smartWizard("setOptions",{errorSteps: [1]})
    else
        $("#forwarding_mobilphone").closest(".field").find("span").remove()
        $("#forwarding_mobilphone").closest(".field").removeClass("error")

$(document).on 'focusout', '#forwarding_mobilphone', ->
    forwarding_mobilphone_validate()


forwarding_google_validate = ->
    constraints =
        google:
            url: 
                message: I18n.t("validate.url")
    error = validate({google: if $("#forwarding_google").val() == "" then null else $("#forwarding_google").val()}, constraints)
    if error != undefined && error.google != undefined
        $("#forwarding_google").closest(".field").find("span").remove()
        $("#forwarding_google").closest(".field").addClass("error")
        $("#forwarding_google").closest(".field").append("<span class='ui red text'>"+error.google.join(" <br>")+"</span>")
        $('#smartwizard').smartWizard("setOptions",{errorSteps: [1]})
    else
        $("#forwarding_google").closest(".field").find("span").remove()
        $("#forwarding_google").closest(".field").removeClass("error")

$(document).on 'focusout', '#forwarding_google', ->
    forwarding_google_validate()

forwarding_instagram_validate = ->
    constraints =
        instagram:
            url: 
                message: I18n.t("validate.url")
    error = validate({instagram: if $("#forwarding_instagram").val() == "" then null else $("#forwarding_instagram").val()}, constraints)
    if error != undefined && error.instagram != undefined
        $("#forwarding_instagram").closest(".field").find("span").remove()
        $("#forwarding_instagram").closest(".field").addClass("error")
        $("#forwarding_instagram").closest(".field").append("<span class='ui red text'>"+error.instagram.join(" <br>")+"</span>")
        $('#smartwizard').smartWizard("setOptions",{errorSteps: [1]})
    else
        $("#forwarding_instagram").closest(".field").find("span").remove()
        $("#forwarding_instagram").closest(".field").removeClass("error")

$(document).on 'focusout', '#forwarding_instagram', ->
    forwarding_instagram_validate()

forwarding_facebook_validate = ->
    constraints =
        facebook:
            url: 
                message: I18n.t("validate.url")
    error = validate({facebook: if $("#forwarding_facebook").val() == "" then null else $("#forwarding_facebook").val()}, constraints)
    if error != undefined && error.facebook != undefined
        $("#forwarding_facebook").closest(".field").find("span").remove()
        $("#forwarding_facebook").closest(".field").addClass("error")
        $("#forwarding_facebook").closest(".field").append("<span class='ui red text'>"+error.facebook.join(" <br>")+"</span>")
        $('#smartwizard').smartWizard("setOptions",{errorSteps: [1]})
    else
        $("#forwarding_facebook").closest(".field").find("span").remove()
        $("#forwarding_facebook").closest(".field").removeClass("error")

$(document).on 'focusout', '#forwarding_facebook', ->
    forwarding_facebook_validate()

forwarding_check_out_after_validate = ->
    constraints =
        check_out_after:
            presence: 
                message: I18n.t("validate.reguired_check_out_after")
                allowEmpty: false
            numericality: 
                notValid: I18n.t("validate.notInteger")
                onlyInteger: true
                notInteger: I18n.t("validate.notInteger")
                greaterThan: 0
                notGreaterThan: I18n.t("validate.notGreaterThan")
    error = validate({check_out_after: parseInt($("#forwarding_check_out_after").val())}, constraints)
    if error != undefined && error.check_out_after != undefined
        $("#forwarding_check_out_after").closest(".field").find("span").remove()
        $("#forwarding_check_out_after").closest(".field").addClass("error")
        $("#forwarding_check_out_after").closest(".field").append("<span class='ui red text'>"+error.check_out_after.join(" <br>")+"</span>")
        $('#smartwizard').smartWizard("setOptions",{errorSteps: [0]})
    else
        $("#forwarding_check_out_after").closest(".field").find("span").remove()
        $("#forwarding_check_out_after").closest(".field").removeClass("error")

$(document).on 'focusout', '#forwarding_check_out_after', ->
    forwarding_check_out_after_validate()