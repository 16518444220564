$(document).on 'turbolinks:load', ->
    $.fn.dataTable.moment('DD.MM.YYYY HH:mm')
    $('#tableforusers').DataTable({
        language: { 
            url: '/datatable_translation'
        }
        ajax: "/users.json",
        sAjaxDataProp: "users",
        columns: [
            { "data": "name" },
            { "data": "email" },
            { "data": "role" },
            { "data": "updated_at" },
            { "data": "logged_in" },
            { "data": "active" }
        ],
        dom: "<'ui stackable grid'<'three column row' <'column' l> <'column' B > <'column' f>> <'one column row' <'column' rt>> <'three column row' <'column' i ><'column' ><'column' p >>>",
        lengthChange: true,
        lengthMenu: [10, 20, 50],
        buttons: [
            $.extend(false, {}, extend: 'csvHtml5', className: 'ui primary button', fieldSeparator: ';', charset: 'UTF-8')
        ], 
        order: [[ 0, "asc" ]] ,
        drawCallback: (settings) ->
            $('.dropdown').dropdown() 
        }
    )

    $('#tableforcompanies').DataTable({
        language: { 
            url: '/datatable_translation'
        }
        ajax: "/companies.json",
        sAjaxDataProp: "companies",
        columns: [
            { "data": "name" },
            { "data": "email" },
            { "data": "role" },
            { "data": "updated_at" },
            { "data": "logged_in" },
            { "data": "locality" },
            { "data": "active" }
        ],
        dom: "<'ui stackable grid'<'three column row' <'column' l> <'column' B > <'column' f>> <'one column row' <'column' rt>> <'three column row' <'column' i ><'column' ><'column' p >>>",
        lengthChange: true,
        lengthMenu: [10, 20, 50],
        buttons: [
            $.extend(false, {}, extend: 'csvHtml5', className: 'ui primary button', fieldSeparator: ';', charset: 'UTF-8')
        ], 
        order: [[ 0, "asc" ]] ,
        drawCallback: (settings) ->
            $('.dropdown').dropdown() 
        }
    )

    $('#tableforcontacts').DataTable({
        language: {
            url: '/datatable_translation'
        }
        ajax: "/contacts.json",
        sAjaxDataProp: "contacts",
        columns: [
            { "data": "name" },
            { "data": "email" },
            { "data": "subject" },
            { "data": "comment" },
            { "data": "active" },
            { "data": "alert" },
            { "data": "updated_at" }
        ],
        dom: "<'ui stackable grid'<'three column row' <'column' l> <'column' B > <'column' f>> <'one column row' <'column' rt>> <'three column row' <'column' i ><'column' ><'column' p >>>",
        lengthChange: true,
        lengthMenu: [100],
        buttons: [
            $.extend(false, {}, extend: 'csvHtml5', className: 'ui primary button', fieldSeparator: ';', charset: 'UTF-8')
        ], 
        order: [[ 0, "asc" ]],
        drawCallback: (settings) ->
            $('.dropdown').dropdown() 
        }
    )
    
    $('#tableforforwardings').DataTable({
        language: {
            url: '/datatable_translation'
        }
        ajax: "/forwardings.json",
        sAjaxDataProp: "forwardings",
        columns: [
            { "data": "image" },
            { "data": "name" },
            { "data": "locality_type" },
            { "data": "views" },
            { "data": "active" },
            { "data": "updated_at" },
            { "data": "qr" },
            { "data": "checkout_view" },
            { "data": "table_view" },
            { "data": "dashboard" },
            { "data": "access_rights" }
        ],
        dom: "<'ui stackable grid'<'three column row' <'column' l>  <'column' B > <'column' f>> <'one column row' <'column' rt>> <'three column row' <'column' i ><'column' ><'column' p >>>",
        lengthChange: true,
        lengthMenu: [10, 20, 50],
        buttons: [
            $.extend(false, {}, extend: 'csvHtml5', className: 'ui primary button', fieldSeparator: ';', charset: 'UTF-8')
        ], 
        order: [[ 0, "asc" ]] ,
        drawCallback: (settings) ->
            $('.dropdown').dropdown() 
        }
    )    

    $('#tablecompanyforforwardings').DataTable({
        language: {
            url: '/datatable_translation'
        }
        ajax: "/companies/"+$("body").data("id")+".json?key=forwarding",
        sAjaxDataProp: "forwardings",
        columns: [
            { "data": "name" },
            { "data": "views" },
            { "data": "qr" }, 
            { "data": "checkout_view" },
            { "data": "table_view" },
            { "data": "dashboard" }
        ],
        dom: "<'ui stackable grid'<'three column row' <'column' l>  <'column' B > <'column' f>> <'one column row' <'column' rt>> <'three column row' <'column' i ><'column' ><'column' p >>>",
        lengthChange: true,
        lengthMenu: [10, 20, 50],
        buttons: [
            $.extend(false, {}, extend: 'csvHtml5', className: 'ui primary button', fieldSeparator: ';', charset: 'UTF-8')
        ], 
        order: [[ 0, "asc" ]] ,
        drawCallback: (settings) ->
            $('.dropdown').dropdown() 
        }
    ) 
    
    $('#tablefornotifications').DataTable({
        language: {
            url: '/datatable_translation'
        }
        ajax: "/notifications.json?key=all",
        sAjaxDataProp: "notifications",
        columns: [
            { "data": "status" }, 
            { "data": "model_name" }, 
            { "data": "message" }, 
            { "data": "cause" }, 
            { "data": "updated_at" }
        ],
        dom: "<'ui stackable grid'<'three column row' <'column' l> <'column' B > <'column' f>> <'one column row' <'column' rt>> <'three column row' <'column' i ><'column' ><'column' p >>>",
        lengthChange: true,
        lengthMenu: [10, 20, 50],
        buttons: [
            $.extend(false, {}, extend: 'csvHtml5', className: 'ui primary button', fieldSeparator: ';', charset: 'UTF-8')
        ], 
        order: [[ 4, "desc" ]],
        drawCallback: (settings) ->
            $('.dropdown').dropdown() 
        createdRow: (row, data, dataIndex) ->
            $(row).find('td:eq(0)').addClass(data.status_class)
        }
    )

    $('#tableforlogs').DataTable({
        language: {
            url: '/datatable_translation'
        }
        ajax: "/notifications.json?key=log&modelnametyp="+$('#tableforlogs').data("model-name-typ")+"&modelid="+$('#tableforlogs').data("model-id"),
        sAjaxDataProp: "notifications",
        columns: [
            { "data": "status" }, 
            { "data": "model_name" }, 
            { "data": "message" }, 
            { "data": "cause" }, 
            { "data": "updated_at" }
        ],
        dom: "<'ui stackable grid'<'three column row' <'column' l> <'column' B > <'column' f>> <'one column row' <'column' rt>> <'three column row' <'column' i ><'column' ><'column' p >>>",
        lengthChange: true,
        lengthMenu: [10, 20, 50],
        buttons: [
            $.extend(false, {}, extend: 'csvHtml5', className: 'ui primary button', fieldSeparator: ';', charset: 'UTF-8')
        ], 
        order: [[ 0, "asc" ]],
        drawCallback: (settings) ->
            $('.dropdown').dropdown() 
        createdRow: (row, data, dataIndex) ->
            $(row).find('td:eq(0)').addClass(data.status_class)
        }
    )

    $('#tableforlogins').DataTable({
        language: {
            url: '/datatable_translation'
        }
        ajax: "/logins.json?key=log&date="+$('#tableforlogins').data("date")+"&user="+$('#tableforlogins').data("user"),
        sAjaxDataProp: "logins",
        columns: [
            { "data": "identity" }, 
            { "data": "ip" }, 
            { "data": "user_agent" }, 
            { "data": "referrer" }, 
            { "data": "created_at" }
        ],
        dom: "<'ui stackable grid'<'three column row' <'column' l> <'column' B > <'column' f>> <'one column row' <'column' rt>> <'three column row' <'column' i ><'column' ><'column' p >>>",
        lengthChange: true,
        lengthMenu: [10, 20, 50],
        buttons: [
            $.extend(false, {}, extend: 'csvHtml5', className: 'ui primary button', fieldSeparator: ';', charset: 'UTF-8')
        ], 
        order: [[ 0, "asc" ]],
        drawCallback: (settings) ->
            $('.dropdown').dropdown()
        }
    )

    $('#tableforusertries').DataTable({
        language: {
            url: '/datatable_translation'
        }
        ajax: "/user_tries.json?key=log&date="+$('#tableforusertries').data("date"),
        sAjaxDataProp: "user_tries",
        columns: [
            { "data": "identity" }, 
            { "data": "ip" }, 
            { "data": "user_agent" }, 
            { "data": "referrer" }, 
            { "data": "created_at" }
        ],
        dom: "<'ui stackable grid'<'three column row' <'column' l> <'column' B > <'column' f>> <'one column row' <'column' rt>> <'three column row' <'column' i ><'column' ><'column' p >>>",
        lengthChange: true,
        lengthMenu: [10, 20, 50],
        buttons: [
            $.extend(false, {}, extend: 'csvHtml5', className: 'ui primary button', fieldSeparator: ';', charset: 'UTF-8')
        ], 
        order: [[ 0, "asc" ]],
        drawCallback: (settings) ->
            $('.dropdown').dropdown()
        }
    )


    $('#tableforbillings').DataTable({
        language: {
            url: '/datatable_translation'
        }
        ajax: "/forwardings/"+$("body").data("id")+".json?key=billing",
        sAjaxDataProp: "billings",
        columns: [
            { "data": "month" }, 
            { "data": "days" }
        ],
        dom: "<'ui stackable grid'<'three column row' <'column' l>  <'column' B > <'column' f>> <'one column row' <'column' rt>> <'three column row' <'column' i ><'column' ><'column' p >>>",
        lengthChange: true,
        lengthMenu: [10, 20, 50],
        buttons: [
            $.extend(false, {}, extend: 'csvHtml5', className: 'ui primary button', fieldSeparator: ';', charset: 'UTF-8')
        ], 
        order: [[ 0, "asc" ]] ,
        drawCallback: (settings) ->
            $('.dropdown').dropdown() 
        }
    ) 
    $('#tableforcoronavisits').DataTable({
        language: {
            url: '/datatable_translation'
        }
        ajax: "/forwardings/"+$("body").data("id")+".json?key=corona&limit=100",
        sAjaxDataProp: "corona",
        columns: [
            { "data": "name" }, 
            { "data": "company" },
            { "data": "contact_person" }, 
            { "data": "nda" }, 
            { "data": "confidentiality_agreement" }, 
            { "data": "number" }, 
            { "data": "start" }, 
            { "data": "end" }
        ],
        dom: "<'ui stackable grid'<'three column row' <'column' l>  <'column' B > <'column' f>> <'one column row' <'column' rt>> <'three column row' <'column' i ><'column' ><'column' p >>>",
        lengthChange: true,
        lengthMenu: [10, 20, 50],
        buttons: [
            $.extend(false, {}, extend: 'csvHtml5', className: 'ui primary button', fieldSeparator: ';', charset: 'UTF-8')
        ], 
        order: [[ 5, "desc" ]] ,
        drawCallback: (settings) ->
            $('.dropdown').dropdown() 
        }
    ) 

    $('#tableforapikeys').DataTable({
        language: {
            url: '/datatable_translation'
        }
        ajax: "/api_keys.json",
        sAjaxDataProp: "api_keys",
        columns: [
            { "data": "name" }, 
            { "data": "company_id" }, 
            { "data": "comment" }, 
            { "data": "expiry_date" }, 
            { "data": "active" }, 
            { "data": "alert" }, 
            { "data": "updated_at" }
        ],
        dom: "<'ui stackable grid'<'three column row' <'column' l>  <'column' B > <'column' f>> <'one column row' <'column' rt>> <'three column row' <'column' i ><'column' ><'column' p >>>",
        lengthChange: true,
        lengthMenu: [10, 20, 50],
        buttons: [
            $.extend(false, {}, extend: 'csvHtml5', className: 'ui primary button', fieldSeparator: ';', charset: 'UTF-8')
        ], 
        order: [[ 2, "desc" ]] ,
        drawCallback: (settings) ->
            $('.dropdown').dropdown() 
        }
    ) 

    

$(document).on 'change', '#lastcorona', ->
    $('#tableforcoronavisits').DataTable().destroy()
    $('#tableforcoronavisits').DataTable({
        language: {
            url: '/datatable_translation'
        }
        ajax: "/forwardings/"+$("body").data("id")+".json?key=corona&limit="+$(this).val(),
        sAjaxDataProp: "corona",
        columns: [
            { "data": "name" },
            { "data": "company" },
            { "data": "contact_person" }, 
            { "data": "number" }, 
            { "data": "start" }, 
            { "data": "end" }
        ],
        dom: "<'ui stackable grid'<'three column row' <'column' l>  <'column' B > <'column' f>> <'one column row' <'column' rt>> <'three column row' <'column' i ><'column' ><'column' p >>>",
        lengthChange: true,
        lengthMenu: [10, 20, 50],
        buttons: [
            $.extend(false, {}, extend: 'csvHtml5', className: 'ui primary button', fieldSeparator: ';', charset: 'UTF-8')
        ], 
        order: [[ 2, "desc" ]], 
        drawCallback: (settings) ->
            $('.dropdown').dropdown() 
        }
    ) 

$(document).on 'turbolinks:before-cache', ->
    $('#tableforusers').DataTable().destroy()
    $('#tableforcompanies').DataTable().destroy()
    $('#tableforcontacts').DataTable().destroy()
    $('#tableforforwardings').DataTable().destroy()
    $('#tablecompanyforforwardings').DataTable().destroy()
    $('#tableforbillings').DataTable().destroy()
    $('#tablefornotifications').DataTable().destroy()
    $('#tableforlogs').DataTable().destroy()
    $('#tableforlogins').DataTable().destroy()
    $('#tableforusertries').DataTable().destroy()
    $('#tableforcoronavisits').DataTable().destroy()
    $('#tableforapikeys').DataTable().destroy()
    