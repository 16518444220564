import I18n from 'i18n-js'
moment = require('moment')
validate = require("validate.js")

$(document).on 'turbolinks:load', ->
    I18n.translations = JSON.parse(gon.js_translations)
    I18n.defaultLocale = "de"
    I18n.locale = "de"
    if $("body").data("controller") == "home" && $("body").data("action") == "success_registry"        
        smartwizard = $('#successwizard').smartWizard({
            selected: 0, 
            theme: 'dots', 
            justified: true,
            darkMode:false, 
            autoAdjustHeight: false, 
            cycleSteps: false,
            backButtonSupport: true,
            enableURLhash: false, 
            lang: { 
                next: 'Nächste',
                previous: 'Zurück'
            }
        })
    if $("body").data("controller") == "home" && ($("body").data("action") == "corona_registry" || $("body").data("action") == "corona_registry_save")
        if document.cookie.indexOf('registration=') == 0
            smartwizard = $('#covidwizard').smartWizard({
                selected: 1, 
                theme: 'dots', 
                justified: true, 
                darkMode:false, 
                autoAdjustHeight: false, 
                cycleSteps: false,
                backButtonSupport: true,
                enableURLhash: false,
                lang: { 
                    next: 'Nächste',
                    previous: 'Zurück'
                }
                hiddenSteps: [0]
            })
        else
            smartwizard = $('#covidwizard').smartWizard({
                selected: 0, 
                theme: 'dots', 
                justified: true, 
                darkMode:false, 
                autoAdjustHeight: false, 
                cycleSteps: false,
                backButtonSupport: true,
                enableURLhash: false,
                lang: { 
                    next: 'Nächste',
                    previous: 'Zurück'
                }
            })

$(document).on 'click', '#addguest', (e) ->
    e.preventDefault();
    $(this).after("<div class='field'><label>Vollständiger Name des weiteren Gastes</label><div class='ui action input'><input name='guest[]' type='text'><button class='ui red icon button' id='removeguest'><i class='trash icon'></i></button></div></div>")

$(document).on 'click', '#removeguest', (e) ->
    e.preventDefault();
    $(this).closest(".field").remove()

$(document).on 'leaveStep', '#covidwizard', (e, anchorObject, currentStepIndex, nextStepIndex, stepDirection) ->
    $(anchorObject).removeClass("danger")
    if currentStepIndex == 1
        index = 1 
        if $("#corona_registry_name").length != 0
            $($("#step-3").find("span.ui")[index]).html($("#corona_registry_name").val())
            index++
        if $("#corona_registry_company").length != 0 
            $($("#step-3").find("span.ui")[index]).html($("#corona_registry_company").val())
            index++
        if $("#corona_registry_contact_person").length != 0
            $($("#step-3").find("span.ui")[index]).html($("#corona_registry_contact_person").val())
            index++
        if $("#corona_registry_nda").length != 0
            if $("#corona_registry_nda").is(':checked')
                $($("#step-3").find("span.ui")[index]).html("<span style='color: Green;'><i class='check icon'></i></span>")
            else
                $($("#step-3").find("span.ui")[index]).html("<span style='color: Tomato;'><i class='times icon'></i></span>")
            index++
        if $("#corona_registry_confidentiality_agreement").length != 0 
            if $("#corona_registry_confidentiality_agreement").is(':checked')
                $($("#step-3").find("span.ui")[index]).html("<span style='color: Green;'><i class='check icon'></i></span>")
            else
                $($("#step-3").find("span.ui")[index]).html("<span style='color: Tomato;'><i class='times icon'></i></span>")
            index++
        if $("#corona_registry_phone").length != 0
            $($("#step-3").find("span.ui")[index]).html($("#corona_registry_prefix").val()+$("#corona_registry_phone").val())
            index++
        if $("#corona_registry_zip").length != 0
            $($("#step-3").find("span.ui")[index]).html($("#corona_registry_zip").val())
            index++
        if $("#corona_registry_city").length != 0
            $($("#step-3").find("span.ui")[index]).html($("#corona_registry_city").val())
            index++
        if $("#corona_registry_street").length != 0
            $($("#step-3").find("span.ui")[index]).html($("#corona_registry_street").val())
            index++
        if $("#corona_registry_email").length != 0
            $($("#step-3").find("span.ui")[index]).html($("#corona_registry_email").val())
            index++
        if $("#corona_registry_table_number").length != 0
            $($("#step-3").find("span.ui")[index]).html($("#corona_registry_table_number").val())
            index++
        types = [];
        $.each $('input[name="guest[]"]'), (index, value) ->
            types.push($(value).val());
        if $('input[name="guest[]"]').length != 0
            $($("#step-3").find("span.ui")[index]).html(types.join("<br>"))
        corona_registry_name_validate()
        corona_registry_phone_validate()
        corona_registry_city_validate()
        corona_registry_zip_validate()
        corona_registry_street_validate()
        corona_registry_table_number_validate()
        corona_registry_email_validate()
        corona_registry_contact_person_validate()
        corona_registry_company_validate()
        $.each $('input[name="guest[]"]'), (index, value) ->
            corona_registry_guest_validate(this)
        if $(anchorObject).hasClass("danger")
            return false
        
corona_registry_name_validate = ->
    if $("#corona_registry_name").length != 0
        constraints =
            name:
                presence: 
                    message: I18n.t("validate.reguired_name")
                    allowEmpty: false
                length: 
                    minimum: 2
                    message: I18n.t("validate.length")
                exclusion:
                    within: [ 'Mickey mouse', 'Mickey Mouse' ]
                    message: "^%{value}" + I18n.t("validate.exclusion")
        error = validate({name: $("#corona_registry_name").val()}, constraints)
        if error != undefined && error.name != undefined
            $("#corona_registry_name").closest(".field").find("span").remove()
            $("#corona_registry_name").closest(".field").addClass("error")
            $("#corona_registry_name").closest(".field").append("<span class='ui red text'>"+error.name.join(" <br>")+"</span>")
            $('#covidwizard').smartWizard("setOptions",{errorSteps: [1]})
        else
            $("#corona_registry_name").closest(".field").find("span").remove()
            $("#corona_registry_name").closest(".field").removeClass("error") 

$(document).on 'focusout', '#corona_registry_name', ->
    corona_registry_name_validate()

corona_registry_company_validate = ->
    if $("#corona_registry_company").length != 0
        constraints =
            name:
                presence: 
                    message: I18n.t("validate.reguired_company")
                    allowEmpty: false
                length: 
                    minimum: 2
                    message: I18n.t("validate.length")
                exclusion:
                    within: [ 'Mickey mouse', 'Mickey Mouse' ]
                    message: "^%{value}" + I18n.t("validate.exclusion")
        error = validate({name: $("#corona_registry_company").val()}, constraints)
        if error != undefined && error.name != undefined
            $("#corona_registry_company").closest(".field").find("span").remove()
            $("#corona_registry_company").closest(".field").addClass("error")
            $("#corona_registry_company").closest(".field").append("<span class='ui red text'>"+error.name.join(" <br>")+"</span>")
            $('#covidwizard').smartWizard("setOptions",{errorSteps: [1]})
        else
            $("#corona_registry_company").closest(".field").find("span").remove()
            $("#corona_registry_company").closest(".field").removeClass("error") 

$(document).on 'focusout', '#corona_registry_company', ->
    corona_registry_company_validate()

corona_registry_contact_person_validate = ->
    if $("#corona_registry_contact_person").length != 0
        constraints = 
            name:
                presence: 
                    message: I18n.t("validate.reguired_contact_person")
                    allowEmpty: false
                length: 
                    minimum: 2
                    message: I18n.t("validate.length")
                exclusion:
                    within: [ 'Mickey mouse', 'Mickey Mouse' ]
                    message: "^%{value}" + I18n.t("validate.exclusion")
        error = validate({name: $("#corona_registry_contact_person").val()}, constraints)
        if error != undefined && error.name != undefined
            $("#corona_registry_contact_person").closest(".field").find("span").remove()
            $("#corona_registry_contact_person").closest(".field").addClass("error")
            $("#corona_registry_contact_person").closest(".field").append("<span class='ui red text'>"+error.name.join(" <br>")+"</span>")
            $('#covidwizard').smartWizard("setOptions",{errorSteps: [1]})
        else
            $("#corona_registry_contact_person").closest(".field").find("span").remove()
            $("#corona_registry_contact_person").closest(".field").removeClass("error") 

$(document).on 'focusout', '#corona_registry_contact_person', ->
    corona_registry_contact_person_validate()

corona_registry_zip_validate = ->
    if $("#corona_registry_zip").length != 0
        constraints =
            zip:
                presence: 
                    message: I18n.t("validate.reguired_zip")
                    allowEmpty: false
                format: 
                    pattern: /\d{5}(-\d{4})?/
                    message: I18n.t("validate.format_zip")
        error = validate({zip: $("#corona_registry_zip").val()}, constraints)
        if error != undefined && error.zip != undefined
            $("#corona_registry_zip").closest(".field").find("span").remove()
            $("#corona_registry_zip").closest(".field").addClass("error")
            $("#corona_registry_zip").closest(".field").append("<span class='ui red text'>"+error.zip.join(" <br>")+"</span>")
            $('#covidwizard').smartWizard("setOptions",{errorSteps: [1]})
        else
            $("#corona_registry_zip").closest(".field").find("span").remove()
            $("#corona_registry_zip").closest(".field").removeClass("error")

$(document).on 'focusout', '#corona_registry_zip', ->
    corona_registry_zip_validate()

corona_registry_city_validate = ->
    if $("#corona_registry_city").length != 0
        constraints =
            city:
                presence: 
                    message: I18n.t("validate.reguired_city")
                    allowEmpty: false
        error = validate({city: $("#corona_registry_city").val()}, constraints)
        if error != undefined && error.city != undefined
            $("#corona_registry_city").closest(".field").find("span").remove()
            $("#corona_registry_city").closest(".field").addClass("error")
            $("#corona_registry_city").closest(".field").append("<span class='ui red text'>"+error.city.join(" <br>")+"</span>")
            $('#covidwizard').smartWizard("setOptions",{errorSteps: [1]})
        else
            $("#corona_registry_city").closest(".field").find("span").remove()
            $("#corona_registry_city").closest(".field").removeClass("error")

$(document).on 'focusout', '#corona_registry_city', ->
    corona_registry_city_validate()

corona_registry_street_validate = ->
    if $("#corona_registry_street").length != 0
        constraints =
            street:
                presence: 
                    message: I18n.t("validate.reguired_street")
                    allowEmpty: false
        error = validate({street: $("#corona_registry_street").val()}, constraints)
        if error != undefined && error.street != undefined
            $("#corona_registry_street").closest(".field").find("span").remove()
            $("#corona_registry_street").closest(".field").addClass("error")
            $("#corona_registry_street").closest(".field").append("<span class='ui red text'>"+error.street.join(" <br>")+"</span>")
            $('#covidwizard').smartWizard("setOptions",{errorSteps: [1]})
        else
            $("#corona_registry_street").closest(".field").find("span").remove()
            $("#corona_registry_street").closest(".field").removeClass("error")

$(document).on 'focusout', '#corona_registry_street', ->
    corona_registry_street_validate()

corona_registry_phone_validate = ->
    if $("#corona_registry_phone").length != 0
        constraints =
            phone:
                presence: 
                    message: I18n.t("validate.reguired_phone")
                    allowEmpty: false
                format: 
                    pattern: /^[1-9][0-9]*$/
                    message: I18n.t("validate.format_phone")
        error = validate({phone: $("#corona_registry_phone").val()}, constraints)
        if error != undefined && error.phone != undefined
            $("#corona_registry_phone").closest(".field").find("span").remove()
            $("#corona_registry_phone").closest(".field").addClass("error")
            $("#corona_registry_phone").closest(".field").append("<span class='ui red text'>"+error.phone.join(" <br>")+"</span>")
            $('#covidwizard').smartWizard("setOptions",{errorSteps: [1]})
        else
            $("#corona_registry_phone").closest(".field").find("span").remove()
            $("#corona_registry_phone").closest(".field").removeClass("error")

$(document).on 'focusout', '#corona_registry_phone', ->
    corona_registry_phone_validate()

corona_registry_table_number_validate = ->
    if $("#corona_registry_table_number").length != 0
        constraints =
            table_number:
                presence: 
                    message: I18n.t("validate.reguired_table_number")
                    allowEmpty: false
        error = validate({table_number: $("#corona_registry_table_number").val()}, constraints)
        if error != undefined && error.table_number != undefined
            $("#corona_registry_table_number").closest(".field").find("span").remove()
            $("#corona_registry_table_number").closest(".field").addClass("error")
            $("#corona_registry_table_number").closest(".field").append("<span class='ui red text'>"+error.table_number.join(" <br>")+"</span>")
            $('#covidwizard').smartWizard("setOptions",{errorSteps: [1]})
        else
            $("#corona_registry_table_number").closest(".field").find("span").remove()
            $("#corona_registry_table_number").closest(".field").removeClass("error")

$(document).on 'focusout', '#corona_registry_table_number', ->
    corona_registry_table_number_validate()

corona_registry_email_validate = ->
    if $("#corona_registry_email").length != 0
        constraints =
            email:
                presence: 
                    message: I18n.t("validate.reguired_email")
                    allowEmpty: false
                email: 
                    message: I18n.t("validate.email")
        error = validate({email: $("#corona_registry_email").val()}, constraints)
        if error != undefined && error.email != undefined
            $("#corona_registry_email").closest(".field").find("span").remove()
            $("#corona_registry_email").closest(".field").addClass("error")
            $("#corona_registry_email").closest(".field").append("<span class='ui red text'>"+error.email.join(" <br>")+"</span>")
            $('#covidwizard').smartWizard("setOptions",{errorSteps: [1]})
        else
            $("#corona_registry_email").closest(".field").find("span").remove()
            $("#corona_registry_email").closest(".field").removeClass("error")

$(document).on 'focusout', '#corona_registry_email', ->
    corona_registry_email_validate()

corona_registry_guest_validate = (guest)->
    constraints =
        name:
            presence: 
                message: I18n.t("validate.reguired_name")
                allowEmpty: false
            length: 
                minimum: 6
                message: I18n.t("validate.length")
            exclusion:
                within: [ 'Mickey mouse', 'Mickey Mouse' ]
                message: "^%{value}" + I18n.t("validate.exclusion")
    error = validate({name: $(guest).val()}, constraints)
    if error != undefined && error.name != undefined
        $(guest).closest(".field").find("span").remove()
        $(guest).closest(".field").addClass("error")
        $(guest).closest(".field").append("<span class='ui red text'>"+error.name.join(" <br>")+"</span>")
        $('#covidwizard').smartWizard("setOptions",{errorSteps: [1]})
    else
        $(guest).closest(".field").find("span").remove()
        $(guest).closest(".field").removeClass("error")

$(document).on 'focusout', 'input[name="guest[]"]', ->
    corona_registry_guest_validate(this)

$(document).on 'change', '#corona_registry_privacypolicy_check', ->
    if $(this).prop("checked")
        $('input[name="commit"').prop('disabled', false);
        $('#corona_registry_privacypolicy_check').closest(".field").find("span").remove()
    else
        $('input[name="commit"').prop('disabled', true);
        $('#corona_registry_privacypolicy_check').closest(".field").append("<span class='ui red text'><br>"+I18n.t("validate.reguired_privacypolicy")+"</span>")


$(document).on 'submit', '#new_corona_registry', ->
    if !$('#corona_registry_privacypolicy_check').prop("checked")
        $('#corona_registry_privacypolicy_check').closest(".field").append("<span class='ui red text'><br>"+I18n.t("validate.reguired_privacypolicy")+"</span>")
        return false
    corona_registry_name_validate()
    corona_registry_phone_validate()
    corona_registry_city_validate()
    corona_registry_zip_validate()
    corona_registry_street_validate()
    corona_registry_table_number_validate()
    corona_registry_email_validate()
    corona_registry_contact_person_validate()
    corona_registry_company_validate()
    $.each $('input[name="guest[]"]'), (index, value) ->
        corona_registry_guest_validate(this)
    if $(".danger").length > 0
        return false

$(document).on 'click', '#coronashowmodal', ->
    $("#coronaloginmodal").modal("show")