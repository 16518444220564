import consumer from "./consumer"
var table = null
$(document).on('turbolinks:load', function () {

    if ($("body").data("controller") == "forwardings" && $("body").data("action") == "table_view"){
        consumer.subscriptions.create(
            {
                channel: "TableViewChannel",
                forwarding_id: $("body").data("id"),
                token: $('#tablefortableview').data("token")
            }, {
            connected() {
                $($(".statistic")[1]).find("button").addClass("green")
                $($(".statistic")[1]).find("button").removeClass("red")
                $('body').toast({displayTime: 3000, class: 'success', showProgress: 'bottom', classProgress: 'red', title: 'Verbunden mit dem Server!', message: "Sie haben sich erfolgreich mit dem Server verbunden."});
                table = $('#tablefortableview').DataTable({
                    language: {
                        url: '/datatable_translation'
                    },
                    dom: "<'ui stackable grid'<'three column row'> <'one column row' <'column' rt>> <'three column row' >>",
                    lengthChange: false,
                    paging: false,
                    searching: false,
                    lengthMenu: [1000],
                    rowId: 0,
                    order: [[ 0, "asc" ]] 
                    }
                )
            },

            disconnected() {
                $($(".statistic")[1]).find("button").addClass("red")
                $($(".statistic")[1]).find("button").removeClass("green")
                $('body').toast({displayTime: 5000, class: 'error', showProgress: 'bottom', classProgress: 'green', title: 'Verbindung geschlossen!', message: "Die verbindung zum Server wurde geschlossen."});
                $('#tablefortableview').DataTable().destroy()
            },
            rejected(){
                $($(".statistic")[1]).find("button").addClass("red")
                $($(".statistic")[1]).find("button").removeClass("green")
                $('body').toast({displayTime: 5000, class: 'error', showProgress: 'bottom', classProgress: 'green', title: 'Verbindung geschlossen!', message: "Die verbindung zum Server wurde geschlossen."});
                $('#tablefortableview').DataTable().destroy()
            },
            received(data) {
                var today = new Date()
                var dd = String(today.getDate()).padStart(2, '0');
                var mm = String(today.getMonth() + 1).padStart(2, '0'); 
                var yyyy = today.getFullYear();
                var HH = today.getHours();
                var MM = today.getMinutes();
                if (data.method == "add"){
                    $('body').toast({displayTime: 5000, class: 'success', showProgress: 'bottom', classProgress: 'red', title: 'Kunde hat sich registriert!', message: data.name + " Tischnummer: "+ data.table_number});
                    if(table.row("#"+data.table_number).data() == undefined){
                        table.row.add([data.table_number, data.name]).draw( false )  
                    }else{
                        table.row("#"+data.table_number).data([data.table_number, data.name + ", " + table.row("#"+data.table_number).data()[1]]).draw( false )
                    }
                    $($(".statistic")[0]).find(".value").text(parseInt($($(".statistic")[0]).find(".value").text())+1)
                    $($(".statistic")[3]).find(".value").text(mm + '.' + dd + '.' + yyyy + ' ' + HH + ':' + MM)
                    if (parseInt($($(".statistic")[0]).find(".value").text()) >= parseInt($($(".statistic")[1]).find(".value").text())){
                        $($(".statistic")[1]).find(".value").addClass("text-red")
                    }else{
                        $($(".statistic")[1]).find(".value").removeClass("text-red")
                    }
                }else if(data.method == "delete"){
                    var new_row = null;
                    $('body').toast({displayTime: 5000, class: 'warning', showProgress: 'bottom', classProgress: 'green', title: 'Kunde hat sich ausgescheckt!', message: data.name + " Tischnummer: "+ data.table_number});

                    new_row = $.map($(table.row("#"+data.table_number).data()[1]).not('#'+data.id), function(value, index) {
                        return value.outerHTML;
                    });
                    if (new_row.length == 0){
                        table.row("#"+data.table_number).remove().draw( false )
                    }else{
                        table.row("#"+data.table_number).data([data.table_number, new_row.join(", ")]).draw( false )
                    }
                    $($(".statistic")[0]).find(".value").text(parseInt($($(".statistic")[0]).find(".value").text())-1)
                    $($(".statistic")[3]).find(".value").text(mm + '.' + dd + '.' + yyyy + ' ' + HH + ':' + MM)
                    if (parseInt($($(".statistic")[0]).find(".value").text()) >= parseInt($($(".statistic")[1]).find(".value").text())){
                        $($(".statistic")[1]).find(".value").addClass("text-red")
                    }else{
                        $($(".statistic")[1]).find(".value").removeClass("text-red")
                    }
                }
            }
        });
    }
});
