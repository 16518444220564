import consumer from "./consumer"
var table = null
$(document).on('turbolinks:load', function () {

    if ($("body").data("controller") == "forwardings" && $("body").data("action") == "checkout_view"){
        consumer.subscriptions.create(
            {
                channel: "CheckoutViewChannel",
                forwarding_id: $("body").data("id"),
                token: $('#tableforcheckoutview').data("token")
            }, {
            connected() {
                $($(".statistic")[2]).find("button").addClass("green")
                $($(".statistic")[2]).find("button").removeClass("red")
                $('body').toast({displayTime: 3000, class: 'success', showProgress: 'bottom', classProgress: 'red', title: 'Verbunden mit dem Server!', message: "Sie haben sich erfolgreich mit dem Server verbunden."});
                table = $('#tableforcheckoutview').DataTable({
                    language: {
                        url: '/datatable_translation'
                    },
                    dom: "<'ui stackable grid'<'three column row' <'column'> <'column'> <'column' f>> <'one column row' <'column' rt>> <'three column row' >>",
                    lengthChange: false,
                    paging: true,
                    columnDefs: [
                        {
                            "targets": [ 0 ],
                            "visible": false,
                            "searchable": false
                        }
                    ],
                    searching: true,
                    lengthMenu: [1000],
                    rowId: 0,
                    order: [[ 2, "desc" ]] 
                    }
                ) 
            },

            disconnected() {
                $($(".statistic")[2]).find("button").addClass("red")
                $($(".statistic")[2]).find("button").removeClass("green")
                $('body').toast({displayTime: 5000, class: 'error', showProgress: 'bottom', classProgress: 'green', title: 'Verbindung geschlossen!', message: "Die verbindung zum Server wurde geschlossen."});
                $('#tableforcheckoutview').DataTable().destroy()
            },
            rejected(){
                $($(".statistic")[2]).find("button").addClass("red")
                $($(".statistic")[2]).find("button").removeClass("green")
                $('body').toast({displayTime: 5000, class: 'error', showProgress: 'bottom', classProgress: 'green', title: 'Verbindung geschlossen!', message: "Die verbindung zum Server wurde geschlossen."});
                $('#tableforcheckoutview').DataTable().destroy()
            },
            received(data) {
                console.log(data)
                var today = new Date()
                var dd = String(today.getDate()).padStart(2, '0');
                var mm = String(today.getMonth() + 1).padStart(2, '0'); 
                var yyyy = today.getFullYear();
                var HH = today.getHours();
                var MM = today.getMinutes();
                if (data.method == "add"){
                    $('body').toast({displayTime: 5000, class: 'success', showProgress: 'bottom', classProgress: 'red', title: 'Besucher ist registriert!', message: data.name + " Zeit: "+ data.checkin});
                    table.row.add([data.id, data.name, data.checkin, "<button class='ui button red checkout_button' data-id='"+data.id+"'>Auschecken</button>"]).draw( false )  
                    $($(".statistic")[0]).find(".value").text(parseInt($($(".statistic")[0]).find(".value").text())+1)
                    $($(".statistic")[3]).find(".value").text(mm + '.' + dd + '.' + yyyy + ' ' + HH + ':' + MM)
                    if (parseInt($($(".statistic")[0]).find(".value").text()) >= parseInt($($(".statistic")[1]).find(".value").text())){
                        $($(".statistic")[1]).find(".value").addClass("text-red")
                    }else{
                        $($(".statistic")[1]).find(".value").removeClass("text-red")
                    }
                }else if(data.method == "delete"){
                    $('body').toast({displayTime: 5000, class: 'warning', showProgress: 'bottom', classProgress: 'green', title: 'Besucher ist ausgescheckt!', message: data.name + " Zeit: "+ data.checkin});
                    table.row("#"+data.id).remove().draw( false )
                    $($(".statistic")[0]).find(".value").text(parseInt($($(".statistic")[0]).find(".value").text())-1)
                    $($(".statistic")[3]).find(".value").text(mm + '.' + dd + '.' + yyyy + ' ' + HH + ':' + MM)
                    if (parseInt($($(".statistic")[0]).find(".value").text()) >= parseInt($($(".statistic")[1]).find(".value").text())){
                        $($(".statistic")[1]).find(".value").addClass("text-red")
                    }else{
                        $($(".statistic")[1]).find(".value").removeClass("text-red")
                    }
                }
            }
        });
    }
});
