import * as am4core from "@amcharts/amcharts4/core"
import * as am4charts from "@amcharts/amcharts4/charts"
import am4themes_animated from "@amcharts/amcharts4/themes/animated"

jQuery(document).on 'turbolinks:load', ->   
    if $('#user_distribution').length
        am4core.ready ->
            am4core.useTheme(am4themes_animated)

            chart = am4core.create("user_distribution", am4charts.PieChart3D)
            chart.hiddenState.properties.opacity = 0

            chart.legend = new am4charts.Legend()
            chart.data = JSON.parse(gon.js_user_distribution)

            series = chart.series.push(new am4charts.PieSeries3D())
            series.dataFields.value = "value"
            series.dataFields.category = "user" 
            return
    
    if $('#notification_distribution').length
        am4core.ready ->
            am4core.useTheme(am4themes_animated)

            chart = am4core.create("notification_distribution", am4charts.PieChart3D)
            chart.hiddenState.properties.opacity = 0

            chart.legend = new am4charts.Legend()
            chart.data = JSON.parse(gon.js_notification_distribution)

            series = chart.series.push(new am4charts.PieSeries3D())
            series.dataFields.value = "value"
            series.dataFields.category = "type"
            return
