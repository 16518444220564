import I18n from 'i18n-js'
$(document).on 'turbolinks:load', ->   
    I18n.translations = JSON.parse(gon.js_translations)
    $('.ui.accordion').accordion()
    $('.dropdown').dropdown()
    $('.tabular .item').tab()
    $('.ui.calendar').calendar({
        type: 'date'
        formatter: 
            date: (date, settings) -> 
                if !date
                    return ''
                day = date.getDate()
                month = date.getMonth() + 1
                year = date.getFullYear()
                return year + '/' + month + '/' + day        
    })

    $('input:text').click ->
        $(this).parent().find('input:file').click()
        return
    $('input:file', '.ui.action.input').on 'change', (e) ->
        name = Array.from(this.files).map(({name}) => name).join(", ")
        $('input:text', $(e.target).parent()).val name
        return

$(document).on 'click', '#nav_hamburger', ->
    $(".ui.stackable.menu .item").toggleClass("visable") 
    $("#nav_hamburger").removeClass("visable") 
        