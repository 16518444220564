$(document).on 'turbolinks:load', -> 
    $(document).on 'click', '#tableforusers tr td .deactivate', ->
        userid= $(this).attr("data-id")
        thisbutton = $(this)
        $.get
            url: "/users/"+userid+"/deactivated"
            dataType: "json"
            success: (data) ->
                $('#notice').append '<li class="text-success">' + data.notice + '</li>'
                thisbutton.removeClass('green')
                thisbutton.text('Deactivate')
                thisbutton.removeClass('deactivate')
                thisbutton.addClass('activate')
                thisbutton.addClass('red')
                thisbutton=[]
            error: (data) ->
                $('#notice').append '<li class="text-danger">' + data.error + '</li>'
        return

    $(document).on 'click', '#tableforusers tr td .activate', ->
        userid= $(this).attr("data-id")
        thisbutton = $(this)
        $.get
            url: "/users/"+userid+"/activated"
            dataType: "json"
            success: (data) ->
                thisbutton.removeClass('red')
                thisbutton.text('Activate')
                thisbutton.removeClass('activate')
                thisbutton.addClass('deactivate')
                thisbutton.addClass('green')
                thisbutton=[]
                $('#notice').append '<li class="text-success">' + data.notice + '</li>'                
            error: (data) ->
                $('#notice').append '<li class="text-danger">' + data.error + '</li>'
        return
       
    return