import * as am4core from "@amcharts/amcharts4/core"
import * as am4charts from "@amcharts/amcharts4/charts"
import am4themes_animated from "@amcharts/amcharts4/themes/animated"
import * as am4maps from '@amcharts/amcharts4/maps'
import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow'
import I18n from 'i18n-js'

jQuery(document).on 'turbolinks:load', ->
    if $("body").data("controller") == "users" && $("body").data("action") == "dashboard_admin"
        I18n.translations = JSON.parse(gon.js_translations)
        $('#statistics_start_range').calendar({
            type: 'date',
            endCalendar: $('#statistics_end_range'),
            onChange: -> 
                chart_version_views()
            formatter: 
                date: (date, settings) -> 
                    if !date
                        return ''
                    day = date.getDate()
                    month = date.getMonth() + 1
                    year = date.getFullYear()
                    return year + '/' + month + '/' + day
        });
        $('#statistics_end_range').calendar({
            type: 'date',
            startCalendar: $('#statistics_start_range'),
            onChange: -> 
                chart_version_views()
            formatter: 
                date: (date, settings) -> 
                    if !date
                        return ''
                    day = date.getDate()
                    month = date.getMonth() + 1
                    year = date.getFullYear()
                    return year + '/' + month + '/' + day
        });

        $('#statistics_date').calendar({
            type: 'date',
            onChange: -> 
                chart_version_hour_visists()
            formatter: 
                date: (date, settings) -> 
                    if !date
                        return ''
                    day = date.getDate()
                    month = date.getMonth() + 1
                    year = date.getFullYear()
                    return year + '/' + month + '/' + day
        });

        $('#statistics_user_start_range').calendar({
            type: 'date',
            endCalendar: $('#statistics_user_end_range'),
            onChange: -> 
                chart_user_dashboards()
            formatter: 
                date: (date, settings) -> 
                    if !date
                        return ''
                    day = date.getDate()
                    month = date.getMonth() + 1
                    year = date.getFullYear()
                    return year + '/' + month + '/' + day
        });
        $('#statistics_user_end_range').calendar({
            type: 'date',
            startCalendar: $('#statistics_user_start_range'),
            onChange: -> 
                chart_user_dashboards()
            formatter: 
                date: (date, settings) -> 
                    if !date
                        return ''
                    day = date.getDate()
                    month = date.getMonth() + 1
                    year = date.getFullYear()
                    return year + '/' + month + '/' + day
        });

        $('#statistics_company_start_range').calendar({
            type: 'date',
            endCalendar: $('#statistics_company_end_range'),
            onChange: -> 
                chart_company_dashboards()
            formatter: 
                date: (date, settings) -> 
                    if !date
                        return ''
                    day = date.getDate()
                    month = date.getMonth() + 1
                    year = date.getFullYear()
                    return year + '/' + month + '/' + day
        });
        $('#statistics_company_end_range').calendar({
            type: 'date',
            startCalendar: $('#statistics_company_start_range'),
            onChange: -> 
                chart_company_dashboards()
            formatter: 
                date: (date, settings) -> 
                    if !date
                        return ''
                    day = date.getDate()
                    month = date.getMonth() + 1
                    year = date.getFullYear()
                    return year + '/' + month + '/' + day
        });

        $('#statistics_notification_start_range').calendar({
            type: 'date',
            endCalendar: $('#statistics_notification_end_range'),
            onChange: -> 
                chart_notification_dashboards()
            formatter: 
                date: (date, settings) -> 
                    if !date
                        return ''
                    day = date.getDate()
                    month = date.getMonth() + 1
                    year = date.getFullYear()
                    return year + '/' + month + '/' + day
        });
        $('#statistics_notification_end_range').calendar({
            type: 'date',
            startCalendar: $('#statistics_notification_start_range'),
            onChange: -> 
                chart_notification_dashboards()
            formatter: 
                date: (date, settings) -> 
                    if !date
                        return ''
                    day = date.getDate()
                    month = date.getMonth() + 1
                    year = date.getFullYear()
                    return year + '/' + month + '/' + day
        });
        chart_version_views()
        chart_version_hour_visists()
        chart_company_dashboards()
        chart_user_dashboards()
        chart_notification_dashboards()

$(document).on 'change', '#statistics_version', ->
    if $(this).val() == "all"
        $(".hour_table_distribution").html(I18n.t("statistics.hour_table_location"))
    else
        $(".hour_table_distribution").html(I18n.t("statistics.hour_table_distribution"))
    chart_version_views() 

chart_version_views = ->
    if $("body").data("controller") == "users" && $("body").data("action") == "dashboard_admin"
        $("#chart_version_views_message2").html("")
        $.get
            url: "/forwardings/new/statistics_global_views?version="+$("#statistics_version").val()+"&date_start="+$("#statistics_start_range input").val()+"&date_end="+$("#statistics_end_range input").val()
            success: (data) ->
                if data.value_empty 
                    $("#chart_version_views_message2").html("<div class='ui yellow message'>"+I18n.t("statistics.statistics_empty")+"</div>")
                $("#statistics_date_range_min_time").html(data.min_time)
                $("#statistics_date_range_average_time").html(data.average_time)
                $("#statistics_date_range_max_time").html(data.max_time)
                $("#statistics_date_range_sum_time").html(data.sum_time) 
                am4core.ready ->
                    am4core.useTheme am4themes_animated
                    chart = am4core.create("chart_version_views", am4charts.XYChart)
                    chart.paddingRight = 20
                    chart.data = data.view_chart
                    dateAxis = chart.xAxes.push(new (am4charts.DateAxis))
                    dateAxis.renderer.grid.template.location = 0
                    dateAxis.minZoomCount = 5
                    dateAxis.groupData = true
                    dateAxis.groupCount = 500
                    valueAxis = chart.yAxes.push(new (am4charts.ValueAxis))
                    series = chart.series.push(new (am4charts.LineSeries))
                    series.dataFields.dateX = 'date'
                    series.dataFields.valueY = 'value'
                    series.tooltipText = '{valueY}'
                    series.tooltip.pointerOrientation = 'vertical'
                    series.tooltip.background.fillOpacity = 0.5
                    chart.cursor = new (am4charts.XYCursor)
                    chart.cursor.xAxis = dateAxis
                    scrollbarX = new (am4core.Scrollbar)
                    scrollbarX.marginBottom = 20
                    chart.scrollbarX = scrollbarX
                    return

                am4core.ready ->
                    am4core.useTheme(am4themes_animated)

                    chart = am4core.create("date_range_table_distribution", am4charts.PieChart3D)
                    chart.hiddenState.properties.opacity = 0

                    chart.legend = new am4charts.Legend()
                    chart.data = data.hour_tables

                    series = chart.series.push(new am4charts.PieSeries3D())
                    series.dataFields.value = "value"
                    series.dataFields.category = "category"
                    return

                am4core.ready ->
                    am4core.useTheme(am4themes_animated)

                    chart = am4core.create("date_range_hours_distribution", am4charts.XYChart3D)
                    chart.data = data.hour_hours

                    categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis())
                    categoryAxis.dataFields.category = "category"
                    categoryAxis.renderer.labels.template.rotation = 270
                    categoryAxis.renderer.labels.template.hideOversized = false
                    categoryAxis.renderer.minGridDistance = 20
                    categoryAxis.renderer.labels.template.horizontalCenter = "right"
                    categoryAxis.renderer.labels.template.verticalCenter = "middle"
                    categoryAxis.tooltip.label.rotation = 270
                    categoryAxis.tooltip.label.horizontalCenter = "right"
                    categoryAxis.tooltip.label.verticalCenter = "middle"

                    valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
                    valueAxis.title.text = I18n.t("statistics.hour_hours_distribution")
                    valueAxis.title.fontWeight = "bold"

                    series = chart.series.push(new am4charts.ColumnSeries3D())
                    series.dataFields.valueY = "value"
                    series.dataFields.categoryX = "category"
                    series.name = "Visits"
                    series.tooltipText = "{categoryX}: [bold]{valueY}[/]"
                    series.columns.template.fillOpacity = .8

                    columnTemplate = series.columns.template
                    columnTemplate.strokeWidth = 2
                    columnTemplate.strokeOpacity = 1
                    columnTemplate.stroke = am4core.color("#FFFFFF")

                    columnTemplate.adapter.add 'fill', (fill, target) ->
                        chart.colors.getIndex target.dataItem.index

                    columnTemplate.adapter.add 'stroke', (stroke, target) ->
                        chart.colors.getIndex target.dataItem.index

                    chart.cursor = new am4charts.XYCursor();
                    chart.cursor.lineX.strokeOpacity = 0;
                    chart.cursor.lineY.strokeOpacity = 0;
                    
                    return

            error: (data) ->
                if data.responseJSON == undefined
                    $("#chart_version_views_message2").html("<div class='ui red message'>"+I18n.t("statistics.statistics_error")+"</div>")
                else
                    $("#chart_version_views_message2").html("<div class='ui red message'>"+data.responseJSON.message+"</div>")


chart_version_hour_visists = ->
    if $("body").data("controller") == "users" && $("body").data("action") == "dashboard_admin"
        $("#chart_version_views_message3").html("")
        $.get
            url: "/forwardings/"+$("body").data("id")+"/statistics_global_views_hours?version="+$("#statistics_version").val()+"&date="+$("#statistics_date input").val()
            success: (data) ->
                if data.value_empty
                    $("#chart_version_views_message3").html("<div class='ui yellow message'>"+I18n.t("statistics.statistics_empty")+"</div>")
                $("#statistics_hour_min_time").html(data.min_time)
                $("#statistics_hour_average_time").html(data.average_time)
                $("#statistics_hour_max_time").html(data.max_time)
                $("#statistics_hour_sum_time").html(data.sum_time)
                
                am4core.ready ->
                    am4core.useTheme(am4themes_animated)

                    chart = am4core.create("hour_table_distribution", am4charts.PieChart3D)
                    chart.hiddenState.properties.opacity = 0

                    chart.legend = new am4charts.Legend()
                    chart.data = data.hour_tables

                    series = chart.series.push(new am4charts.PieSeries3D())
                    series.dataFields.value = "value" 
                    series.dataFields.category = "category"
                    return
                
                am4core.ready ->
                    am4core.useTheme(am4themes_animated)

                    chart = am4core.create("chart_version_hour_visists", am4charts.XYChart3D)
                    chart.data = data.view_chart

                    categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis())
                    categoryAxis.dataFields.category = "hour"
                    categoryAxis.renderer.labels.template.rotation = 270
                    categoryAxis.renderer.labels.template.hideOversized = false
                    categoryAxis.renderer.minGridDistance = 20
                    categoryAxis.renderer.labels.template.horizontalCenter = "right"
                    categoryAxis.renderer.labels.template.verticalCenter = "middle"
                    categoryAxis.tooltip.label.rotation = 270
                    categoryAxis.tooltip.label.horizontalCenter = "right"
                    categoryAxis.tooltip.label.verticalCenter = "middle"

                    valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
                    valueAxis.title.text = I18n.t("statistics.hour_hours_distribution")
                    valueAxis.title.fontWeight = "bold"

                    series = chart.series.push(new am4charts.ColumnSeries3D())
                    series.dataFields.valueY = "value"
                    series.dataFields.categoryX = "hour"
                    series.name = "Visits"
                    series.tooltipText = "{categoryX}: [bold]{valueY}[/]"
                    series.columns.template.fillOpacity = .8

                    columnTemplate = series.columns.template
                    columnTemplate.strokeWidth = 2
                    columnTemplate.strokeOpacity = 1
                    columnTemplate.stroke = am4core.color("#FFFFFF")

                    columnTemplate.adapter.add 'fill', (fill, target) ->
                        chart.colors.getIndex target.dataItem.index

                    columnTemplate.adapter.add 'stroke', (stroke, target) ->
                        chart.colors.getIndex target.dataItem.index

                    chart.cursor = new am4charts.XYCursor();
                    chart.cursor.lineX.strokeOpacity = 0;
                    chart.cursor.lineY.strokeOpacity = 0;
                    
                    return

            error: (data) ->
                if data.responseJSON == undefined
                    $("#chart_version_views_message3").html("<div class='ui red message'>"+I18n.t("statistics.statistics_error")+"</div>")
                else
                    $("#chart_version_views_message3").html("<div class='ui red message'>"+data.responseJSON.message+"</div>")
chart_company_dashboards = ->
    if $("body").data("controller") == "users" && $("body").data("action") == "dashboard_admin"
        $("#chart_statistics_company_logins_message").html("")
        $.get
            url: "/users/new/company_statistics?date_start="+$("#statistics_user_start_range input").val()+"&date_end="+$("#statistics_user_end_range input").val()
            success: (data) ->
                if data.value_empty
                    $("#chart_statistics_company_logins_message").html("<div class='ui yellow message'>"+I18n.t("statistics.statistics_empty")+"</div>")
                am4core.ready ->
                    am4core.useTheme am4themes_animated
                    chart = am4core.create("chart_statistics_company_logins", am4charts.XYChart)
                    chart.paddingRight = 20
                    chart.data = data.logins
                    dateAxis = chart.xAxes.push(new (am4charts.DateAxis))
                    dateAxis.renderer.grid.template.location = 0
                    dateAxis.minZoomCount = 5
                    dateAxis.groupData = true
                    dateAxis.groupCount = 500
                    valueAxis = chart.yAxes.push(new (am4charts.ValueAxis))
                    series = chart.series.push(new (am4charts.LineSeries))
                    series.dataFields.dateX = 'date'
                    series.dataFields.valueY = 'login'
                    series.tooltipText = 'Logins {valueY}'
                    series.tooltip.pointerOrientation = 'vertical'
                    series.tooltip.background.fillOpacity = 0.5
                    series2 = chart.series.push(new (am4charts.LineSeries))
                    series2.dataFields.dateX = 'date'
                    series2.dataFields.valueY = 'trie'
                    series2.tooltipText = 'Tries {valueY}'
                    series2.tooltip.pointerOrientation = 'vertical'
                    series2.tooltip.background.fillOpacity = 0.5
                    chart.cursor = new (am4charts.XYCursor)
                    chart.cursor.xAxis = dateAxis
                    scrollbarX = new (am4core.Scrollbar)
                    scrollbarX.marginBottom = 20
                    chart.scrollbarX = scrollbarX
                    return
            error: (data) ->
                if data.responseJSON == undefined
                    $("#chart_statistics_company_logins_message").html("<div class='ui red message'>"+I18n.t("statistics.statistics_error")+"</div>")
                else
                    $("#chart_statistics_company_logins_message").html("<div class='ui red message'>"+data.responseJSON.message+"</div>")


chart_user_dashboards = ->
    if $("body").data("controller") == "users" && $("body").data("action") == "dashboard_admin"
        $("#chart_statistics_logins_message").html("")
        $.get
            url: "/users/new/user_statistics?date_start="+$("#statistics_user_start_range input").val()+"&date_end="+$("#statistics_user_end_range input").val()
            success: (data) ->
                if data.value_empty
                    $("#chart_statistics_logins_message").html("<div class='ui yellow message'>"+I18n.t("statistics.statistics_empty")+"</div>")
                am4core.ready ->
                    am4core.useTheme am4themes_animated
                    chart = am4core.create("chart_statistics_logins", am4charts.XYChart)
                    chart.paddingRight = 20
                    chart.data = data.logins
                    dateAxis = chart.xAxes.push(new (am4charts.DateAxis))
                    dateAxis.renderer.grid.template.location = 0
                    dateAxis.minZoomCount = 5
                    dateAxis.groupData = true
                    dateAxis.groupCount = 500
                    valueAxis = chart.yAxes.push(new (am4charts.ValueAxis))
                    series = chart.series.push(new (am4charts.LineSeries))
                    series.dataFields.dateX = 'date'
                    series.dataFields.valueY = 'login'
                    series.tooltipText = 'Logins {valueY}'
                    series.tooltip.pointerOrientation = 'vertical'
                    series.tooltip.background.fillOpacity = 0.5
                    series2 = chart.series.push(new (am4charts.LineSeries))
                    series2.dataFields.dateX = 'date'
                    series2.dataFields.valueY = 'trie'
                    series2.tooltipText = 'Tries {valueY}'
                    series2.tooltip.pointerOrientation = 'vertical'
                    series2.tooltip.background.fillOpacity = 0.5
                    chart.cursor = new (am4charts.XYCursor)
                    chart.cursor.xAxis = dateAxis
                    scrollbarX = new (am4core.Scrollbar)
                    scrollbarX.marginBottom = 20
                    chart.scrollbarX = scrollbarX
                    return
            error: (data) ->
                if data.responseJSON == undefined
                    $("#chart_statistics_logins_message").html("<div class='ui red message'>"+I18n.t("statistics.statistics_error")+"</div>")
                else
                    $("#chart_statistics_logins_message").html("<div class='ui red message'>"+data.responseJSON.message+"</div>")


chart_notification_dashboards = ->
    if $("body").data("controller") == "users" && $("body").data("action") == "dashboard_admin"
        $("#chart_statistics_notification_message").html("")
        $.get
            url: "/users/new/notification_statistics?date_start="+$("#statistics_notification_start_range input").val()+"&date_end="+$("#statistics_notification_end_range input").val()
            success: (data) ->
                if data.value_empty
                    $("#chart_statistics_notification_message").html("<div class='ui yellow message'>"+I18n.t("statistics.statistics_empty")+"</div>")
                am4core.ready ->
                    am4core.useTheme am4themes_animated
                    chart = am4core.create("chart_statistics_notifications", am4charts.XYChart)
                    chart.paddingRight = 20
                    chart.data = data.logins
                    dateAxis = chart.xAxes.push(new (am4charts.DateAxis))
                    dateAxis.renderer.grid.template.location = 0
                    dateAxis.minZoomCount = 5
                    dateAxis.groupData = true
                    dateAxis.groupCount = 500
                    valueAxis = chart.yAxes.push(new (am4charts.ValueAxis))

                    series = chart.series.push(new (am4charts.LineSeries))
                    series.dataFields.dateX = 'date'
                    series.dataFields.valueY = 'systemerror'
                    series.tooltipText = 'Systemerror {valueY}'
                    series.tooltip.pointerOrientation = 'vertical'
                    series.tooltip.background.fillOpacity = 0.5

                    series2 = chart.series.push(new (am4charts.LineSeries))
                    series2.dataFields.dateX = 'date'
                    series2.dataFields.valueY = 'error'
                    series2.tooltipText = 'Error {valueY}'
                    series2.tooltip.pointerOrientation = 'vertical'
                    series2.tooltip.background.fillOpacity = 0.5

                    series3 = chart.series.push(new (am4charts.LineSeries))
                    series3.dataFields.dateX = 'date'
                    series3.dataFields.valueY = 'warning'
                    series3.tooltipText = 'Warning {valueY}'
                    series3.tooltip.pointerOrientation = 'vertical'
                    series3.tooltip.background.fillOpacity = 0.5

                    series4 = chart.series.push(new (am4charts.LineSeries))
                    series4.dataFields.dateX = 'date'
                    series4.dataFields.valueY = 'edit'
                    series4.tooltipText = 'Edit {valueY}'
                    series4.tooltip.pointerOrientation = 'vertical'
                    series4.tooltip.background.fillOpacity = 0.5

                    series5 = chart.series.push(new (am4charts.LineSeries))
                    series5.dataFields.dateX = 'date'
                    series5.dataFields.valueY = 'new'
                    series5.tooltipText = 'New {valueY}'
                    series5.tooltip.pointerOrientation = 'vertical'
                    series5.tooltip.background.fillOpacity = 0.5

                    series6 = chart.series.push(new (am4charts.LineSeries))
                    series6.dataFields.dateX = 'date'
                    series6.dataFields.valueY = 'delete'
                    series6.tooltipText = 'Delete {valueY}'
                    series6.tooltip.pointerOrientation = 'vertical'
                    series6.tooltip.background.fillOpacity = 0.5

                    series7 = chart.series.push(new (am4charts.LineSeries))
                    series7.dataFields.dateX = 'date'
                    series7.dataFields.valueY = 'info'
                    series7.tooltipText = 'Info {valueY}'
                    series7.tooltip.pointerOrientation = 'vertical'
                    series7.tooltip.background.fillOpacity = 0.5

                    chart.cursor = new (am4charts.XYCursor)
                    chart.cursor.xAxis = dateAxis
                    scrollbarX = new (am4core.Scrollbar)
                    scrollbarX.marginBottom = 20
                    chart.scrollbarX = scrollbarX
                    return
            error: (data) ->
                if data.responseJSON == undefined
                    $("#chart_statistics_notification_message").html("<div class='ui red message'>"+I18n.t("statistics.statistics_error")+"</div>")
                else
                    $("#chart_statistics_notification_message").html("<div class='ui red message'>"+data.responseJSON.message+"</div>")